import barnen from "./src/images/barnen@2x.jpg"
import skolan from "./src/images/Skolan@2x.jpg"
import socialWorkImage from "./src/images/socialwork.jpg"
import campImage from "./src/images/camp.jpg"

import medical from "./src/images/medical@2x.jpg"
import older_students from "./src/images/older_students@2x.jpg"

export const cards = t => [
  {
    title: t("Barnen"),
    image: barnen,
    alt: t("Barnen"),
    description: t("Barnen_text"),
  },
  {
    title: t("Skolan"),
    alt: t("Skolan"),
    description: t("Skolan_text"),
    image: skolan,
  },
  {
    title: t("ÄldreElever"),
    alt: t("ÄldreElever"),
    description: t("ÄldreElever_text"),
    image: older_students,
  },
  {
    title: t("Socialt arbete"),
    alt: t("Socialt arbete"),
    description: t("SocialtArbete_text"),
    image: socialWorkImage,
  },
  {
    title: t("Sjukvård"),
    alt: t("Sjukvård"),
    description: t("Sjukvård_text"),
    image: medical,
  },
  {
    title: t("Läger"),
    alt: t("Läger"),
    description: t("Läger_text"),
    image: campImage,
  },
]
