import React from "react"
import styled, { css } from "styled-components"
import Arrow from "react-feather/dist/icons/arrow-right"
import AnchorLink from "../anchor-link/AnchorLink"

export const MainButton = ({ children, ...props }) => (
  <StyledButton {...props}>
    {children}
    <Arrow />
  </StyledButton>
)
export const MainA = ({ children, ...props }) => (
  <StyledAnchorLink {...props}>
    {children}
    <Arrow />
  </StyledAnchorLink>
)
export const NormalA = ({ children, arrow = true, ...props }) => (
  <StyledA {...props}>
    {children}
    {arrow && <Arrow />}
  </StyledA>
)

export const buttonStyles = css`
  border: 0;
  border-radius: 25px;
  padding: 0 30px;
  height: 50px;
  font-size: 20px;
  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  background: linear-gradient(to bottom right, #ffda71, #d3634e);
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    svg {
      transform: translate(4px, 0);
    }
  }
  &:focus,
  &:active {
  }
  svg {
    transition: transform 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    width: 22px;
    margin-left: 5px;
  }
`

export const StyledA = styled.a`
  ${buttonStyles}
`
export const StyledContainerA = styled.div`
  a {
    ${buttonStyles}
  }
`
export const StyledAnchorLink = styled(AnchorLink)`
  ${buttonStyles}
`

export const StyledButton = styled.button`
  ${buttonStyles}
`
