import React from "react"
import styled from "styled-components"
export const Split = ({ first, second, left = true, ...props }) => {
  return (
    <StyledSplit {...props}>
      <div className={`first ${left ? "large" : "small"}`}>{first}</div>
      <div className={`second ${!left ? "large" : "small"}`}>{second}</div>
    </StyledSplit>
  )
}

const StyledSplit = styled.div`
  background: #222222;
  display: flex;
  > div,
  img {
    height: 550px;
  }
  .small {
    width: 45%;
  }
  .large {
    width: 55%;
  }
  img {
    margin: 0;
    width: 100%;
    object-fit: cover;
  }
  .react-reveal {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 760px) {
    /* > div {
      height: 650px;
    } */
    /* img {
      height: 550px;
    } */
    display: block;
    .small,
    .large {
      width: 100%;
    }
    > div,
    img {
      height: auto;
    }
  }
`
