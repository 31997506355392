import React, { Component } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import Recaptcha from "react-google-recaptcha"

import { MainButton } from "./Reusable/Button"
import { useTranslation, Trans } from "react-i18next"

const FlexForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  /* @media screen and (max-width: 600px) {
    margin: 0;
  } */
`

export default class ContactForm extends Component {
  state = {
    error: null,
    loading: false,
    name: "",
    email: "",
    showSuccess: false,
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleRecaptcha = value => {
    this.setState({ "g-recaptcha-response": value })
  }

  handleSubmit = async e => {
    e.preventDefault()
    const form = e.target
    const formName = form.getAttribute("name")

    this.setState({ error: "", loading: true, showSuccess: false })

    const { email, name } = this.state
    if (!email.length || !name.length) {
      this.setState({
        error: "Please fill in your name and email",
        loading: false,
      })
      return
    }
    if (
      !this.state["g-recaptcha-response"] ||
      !this.state["g-recaptcha-response"].length
    ) {
      this.setState({ error: "captch_error", loading: false })
      return
    }

    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": formName,
          ...this.state,
        }),
      })
      this.setState({
        showSuccess: true,
        loading: false,
        name: "",
        email: "",
        message: "",
      })
    } catch (error) {
      this.setState({ error })
    } finally {
      this.setState({ loading: false })
    }
  }

  render() {
    const RECAPTCHA_KEY =
      process.env.SITE_RECAPTCHA_KEY || process.env.GATSBY_SITE_RECAPTCHA_KEY

    const name = "contact"
    return (
      <div {...this.props}>
        <FlexForm
          name={name}
          action="/"
          method="post"
          data-netlify="true"
          // data-netlify-honeypot="bot-field"
          data-netlify-recaptcha="true"
          onSubmit={this.handleSubmit}
        >
          <noscript>
            <p>This form won’t work with Javascript disabled</p>
          </noscript>

          <Inputs handleChange={this.handleChange} state={this.state} />

          {RECAPTCHA_KEY && (
            <Recaptcha
              style={{ margin: "0 auto" }}
              sitekey={RECAPTCHA_KEY}
              onChange={this.handleRecaptcha}
            />
          )}
          {/* <div data-netlify-recaptcha /> */}
          <MainButton
            style={{ marginTop: "10px" }}
            type="submit"
            disabled={this.state.loading}
          >
            {this.state.loading ? "..." : <Trans i18nKey="Send" />}
          </MainButton>
          {!!this.state.error && (
            <RedText>
              <Trans i18nKey={this.state.error} />
            </RedText>
          )}
          {!!this.state.showSuccess && (
            <SuccessText>
              <Trans i18nKey="Tack för ditt meddelande!" />
            </SuccessText>
          )}
        </FlexForm>
      </div>
    )
  }
}

const RedText = styled.div`
  padding: 10px;
  text-align: center;
  color: #ffc107;
`

const SuccessText = styled.div`
  padding: 10px;
  text-align: center;
  color: #fff;
`
function Inputs({ handleChange, state }) {
  const { t } = useTranslation()
  return (
    <>
      <label htmlFor="name">{t("Ditt namn")}</label>
      <InputCell
        type="text"
        id="name"
        name="name"
        value={state.name}
        autoComplete="name"
        onChange={handleChange}
      />
      <label htmlFor="email">{t("Din epost")}</label>
      <InputCell
        type="text"
        id="email"
        name="email"
        value={state.email}
        autoComplete="email"
        onChange={handleChange}
      />
      <label htmlFor="message">{t("Ditt meddelande")}</label>
      <TextAreaCell
        rows={4}
        id="message"
        value={state.message}
        name="message"
        onChange={handleChange}
      />
    </>
  )
}

// export class CampForm extends Component {
//   render() {
//     const { name } = this.props
//     return (
//       <div {...this.props}>
//         <FlexForm
//           name={name}
//           action="thank-you"
//           method="post"
//           data-netlify="true"
//           data-netlify-honeypot="bot-field"
//         >
//           <p hidden>
//             <label>
//               Don’t fill this out if youre human: <input name="bot-field" />
//             </label>
//           </p>
//           <input type="hidden" name="form-name" value={name} />
//           <InputCell
//             type="text"
//             name="name"
//             autoComplete="name"
//             required
//             placeholder="Your name"
//           />
//           <InputCell
//             type="tel"
//             name="phone"
//             autoComplete="tel"
//             required
//             placeholder="Your phone number"
//           />
//           <Submit type="submit">Register</Submit>
//         </FlexForm>
//       </div>
//     )
//   }
// }

export const InputCell = styled.input`
  flex: 1 1 auto;
  margin-bottom: 12px;

  border: 2px solid gray;
  border-radius: 2px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.09);
  color: white;

  :focus {
    outline: 0;
    border: 2px solid #f9c86c;
  }
`
export const TextAreaCell = styled.textarea`
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;
  flex: 1 1 auto;
  margin-bottom: 12px;

  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.09);
  color: white;
  border: 2px solid gray;

  padding: 8px;
  :focus {
    outline: 0;
    border: 2px solid #f9c86c;
  }
`

export const Submit = styled.button`
  color: black;
  padding: 0.6rem;
  margin-top: 0.4rem;

  border-radius: 4px;
  background-color: white;
  border: 1px solid rebeccapurple;
  :focus {
    outline: 0;
    border: 2px solid rebeccapurple;
  }
  :hover {
    color: purple;
  }
  :active {
    color: purple;
  }
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
