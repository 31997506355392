import React from "react"
import styled from "styled-components"

export function CardContainer({ children }) {
  return <GridContainer>{children}</GridContainer>
}

export function Card({ title, description, image, alt = "" }) {
  return (
    <GridItem className="grid-item">
      <StyledCard>
        <img src={image} alt={alt} />
        <h3>{title}</h3>
        <p>{description}</p>
      </StyledCard>
    </GridItem>
  )
}

export const GridContainer = styled.div`
  width: 100%;
  margin: 0px auto;
  max-width: 1280px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
`
export const GridItem = styled.div`
  margin: 0;
  box-sizing: border-box;
  padding: 12px;

  @media (min-width: 600px) {
    padding: 24px;
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
  }
`

export const Container = styled.div`
  padding: 24px;
  background: white;
  display: flex;
  flex-direction: column;

  > div:not(${GridContainer}) {
    padding: 0 24px;
  }
  .grid-item {
    transition: background-color 0.3s ease-in-out;
    border-radius: 4px;
    &:hover {
      background-color: #f5f5f5;
    }
  }
`
const StyledCard = styled.div`
  /* padding: 10px; */
  color: #111;
  overflow: hidden;
  img {
    width: 100%;
    /* height: 300px; */
    object-fit: cover;
  }
`
