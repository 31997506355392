import styled from "styled-components"

export const GradientDivider = styled.div`
  background: linear-gradient(90deg, #efaf64, #d3634e);
  height: 250px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  h2 {
    margin: 0;
    padding: 20px;
    font-size: 42px;
    text-rendering: unset;
  }
`
export const WhiteDivider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: #111;
  padding: 50px;

  p {
    font-size: 20px;
    margin-left: 50px;
  }
  .gatsby-image-wrapper {
    margin: 0;
    width: 50%;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: calc(10px + 2%);
    .gatsby-image-wrapper {
      margin-bottom: 20px;
      width: 80%;
    }
    p {
      margin-left: 10px;
    }
  }
`

export const StyledVolonteerFAQ = styled.div`
  max-width: 50%;
  @media screen and (max-width: 900px) {
    max-width: unset;
  }
`
export const MariaText = styled.div`
  max-width: 60%;
  @media screen and (max-width: 900px) {
    max-width: unset;
  }
`
export const DarkContainer = styled.div`
  background: #222222;
  height: 100%;
  padding: 10%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* justify-content: space-between; */
  h2 {
    /* margin: 0; */
    font-size: 42px;
    text-rendering: unset;
  }
  p {
    font-size: 20px;
  }
  @media screen and (max-width: 900px) {
    p {
      font-size: 18px;
    }
  }

  /* @media screen and (max-width: 600px) {
    iframe {
      display: none;
    }
  } */
`
export const DarkDiv = styled.div`
  background: #222222;
  padding: calc(20px + 2%);
  color: white;
  h2 {
    /* margin: 0; */
    font-size: 42px;
  }
  p {
    font-size: 20px;
  }
`

export const GrayDivider = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;

  background: #eee;
  color: #111;
  padding: 10%;
`
