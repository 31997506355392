import React from "react"
import styled from "styled-components"
import AnchorLink from "../components/anchor-link/AnchorLink"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"

const items = (t: TFunction) => [
  {
    href: "#home",
    title: t("Hem"),
  },
  {
    href: "#help",
    title: t("Hjälp till"),
  },
  {
    href: "#volunteer",
    title: t("Volontärer"),
  },
  {
    href: "#contact",
    title: t("Kontakt"),
  },
]
export function Menu() {
  const { t } = useTranslation()
  return (
    <StyledMenu>
      <ul>
        {items(t).map((item, key) => (
          <li key={key}>
            <AnchorLink
              href={item.href}
              onClick={() => {
                if (window.ga) {
                  window.ga("set", "page", `${item.href}`)
                }
              }}
            >
              {item.title}
            </AnchorLink>
          </li>
        ))}
      </ul>
    </StyledMenu>
  )
}

const StyledMenu = styled.div`
  position: relative;
  top: -50px;
  margin: 0 auto;
  /* margin-left: 48px; */
  ul {
    display: inline-flex;
    background: linear-gradient(90deg, #efaf64, #d3634e);
    margin: 0;
    border: 2px solid #efaf64;
    border-radius: 25px;
    padding: 0 20px;
    li {
      margin: 0;
      list-style: none;
      color: white;

      justify-content: center;
      align-items: center;
      display: flex;

      &:hover,
      &.active {
        a {
          color: #333;
        }
      }
    }
  }
  a {
    color: white;
    text-decoration: none;
    white-space: nowrap;
    padding: 10px 30px;
    letter-spacing: 1px;
    font-family: "Open Sans", sans-serif;
  }
  @media screen and (max-width: 600px) {
    /* top: -60px; */
    left: -24px;
    top: 0;
    margin-bottom: 24px;
    a {
      padding: 10px 10px;
    }
    padding: 0;
    margin-left: 0;
    margin-left: 0;
    width: calc(100% + 48px);
    ul {
      width: 100%;
      justify-content: center;
    }
  }
  @media screen and (max-width: 400px) {
    ul {
      display: block;
    }
  }
`
