import React, { useState } from "react"

import Layout from "../components/Layout/Layout"
// import Image from "../components/image"

import SEO from "../components/Layout/Seo"
// import background from "../images/background.jpg"

import styled from "styled-components"

import Fade from "react-reveal/Fade"
import Rodal from "rodal"
import "rodal/lib/rodal.css"
import ConfettiDisplayer from "../components/ConfettiDisplayer"

import { useTranslation } from "react-i18next"

import { Card, CardContainer, Container } from "../components/Card"

import { cards } from "../../cards"
import { Heading } from "../components/Layout/Heading"
import { Menu } from "../components/Menu"
import { Split } from "../components/Split"

import { Trans } from "react-i18next"
import ContactForm from "../components/ContactForm"
import {
  BackgroundImage,
  ChildrenHangingImage,
  HelpUsImage,
  MariaImage,
  SchoolBackground,
  VolonteerImage,
} from "../components/Images"
import { Map } from "../components/Map"
import {
  MainA,
  MainButton,
  NormalA,
  StyledContainerA,
} from "../components/Reusable/Button"
import {
  DarkContainer,
  DarkDiv,
  GradientDivider,
  MariaText,
  StyledVolonteerFAQ,
  WhiteDivider,
} from "../components/Reusable/Dividers"

const IndexPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <div id="home"></div>
      <SEO title="Home" />
      <BackgroundImage />
      <Heading />
      <Cards />

      <HelpUs />
      <GradientDivider>
        <Fade>
          <h2>{t("Tillsammans förändrar vi världen – ett barn i taget")}</h2>
        </Fade>
      </GradientDivider>
      <WhiteDivider>
        <MariaImage />
        <MariaText>
          <p>
            <i>{t(`mariaText1`)}</i>
          </p>
          <p>
            <Trans i18nKey="mariaText2" />
          </p>
        </MariaText>
      </WhiteDivider>
      <SchoolBackground />
      <Volonteer />
      <VolonteerFAQ />
      <LatestNews />
      <Map />
      <Contact />
    </Layout>
  )
}
function LatestNews() {
  const { t, i18n } = useTranslation()
  let english = true
  if (i18n) {
    english = i18n.language.includes("en")
  }
  return (
    <Split
      id="news"
      first={
        <DarkContainer id="news">
          <Fade left>
            <div>
              <h2>{t("Latest news")}</h2>
              <p>
                <Trans i18nKey="newsText" />
              </p>
              {!english && (
                <div>
                  <StyledContainerA key="little_lambs_final_container">
                    <a
                      key="little_lambs_final"
                      // arrow={false}
                      href={"/little_lambs_final.pdf"}
                      download
                      // target="_blank"
                      // rel="noopener noreferrer"
                    >
                      {t("Ladda ner broschyr")}
                    </a>
                    <br />
                    <br />
                  </StyledContainerA>
                </div>
              )}
              <div>
                <NormalA
                  key="facebook"
                  href="https://www.facebook.com/littlelambsschool"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans i18nKey="follow_us_on_facebook" />
                </NormalA>
              </div>
            </div>
          </Fade>
        </DarkContainer>
      }
      second={
        <Fade>
          <FlexCenterDiv>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/ZX3VIpkiVCo"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </FlexCenterDiv>
        </Fade>
      }
    />
  )
}
function Volonteer() {
  const { t } = useTranslation()

  return (
    <Split
      id="volunteer"
      first={
        <DarkContainer>
          <Fade left>
            <h2>{t("Jobba som volontär")}</h2>
            <p>
              <Trans i18nKey="volonteerText" />
            </p>
            <div>
              <MainA href="#contact">
                <Trans i18nKey="contactUs" />
              </MainA>
            </div>
          </Fade>
        </DarkContainer>
      }
      second={
        <Fade>
          <VolonteerImage />
        </Fade>
      }
    />
  )
}
function VolonteerFAQ() {
  return (
    <WhiteDivider>
      <ChildrenHangingImage />
      <StyledVolonteerFAQ>
        <p>
          <Trans i18nKey="volonteer1" />
        </p>
        <p>
          <Trans i18nKey="volonteer2" />
        </p>
        <p>
          <Trans i18nKey="volonteer3" />
        </p>
        <p>
          <Trans i18nKey="volonteer4" />
        </p>
        <p>
          <Trans i18nKey="volonteer5" />
        </p>
      </StyledVolonteerFAQ>
    </WhiteDivider>
  )
}

function Cards() {
  const { t } = useTranslation()
  return (
    <Container id="main">
      <Menu></Menu>
      <div>
        <CenterHeading>{t("Om oss")}</CenterHeading>
      </div>
      <CardContainer>
        {cards(t).map((card, idx) => (
          <Card key={idx} {...card}></Card>
        ))}
      </CardContainer>
    </Container>
  )
}

function Contact() {
  const { t } = useTranslation()
  return (
    <DarkDiv id="contact">
      <Fade>
        <TextCenter>
          <h2>
            <Trans i18nKey="contactUs" />
          </h2>

          <p>
            <>
              {t("contactUsText")}
              <br />
              <br />
              {t("address")}:
              <br />
              Vijayalakhsmi Nagar, <br />
              Surapet Road <br />
              Puthagaram <br />
              Chennai, Tamil Nadu 600099, India
            </>
          </p>
        </TextCenter>

        <ContactForm />
      </Fade>
    </DarkDiv>
  )
}

function HelpUs() {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [confetti, setConfetti] = useState(false)
  const hide = () => {
    setVisible(false)
  }
  const show = () => {
    history.pushState({}, "", "#donate")
    window.dispatchEvent(new Event("hashchange"))
    if (window.ga) {
      window.ga("set", "page", `#donate`)
    }
    setVisible(true)
    setConfetti(true)
  }
  return (
    <>
      <Split
        id="help"
        first={
          <Fade>
            <HelpUsImage />
          </Fade>
        }
        second={
          <DarkContainer>
            <Fade>
              <h2>{t("helpUsHeading")}</h2>
              <p>
                <Trans i18nKey="helpUsText" />
              </p>
              <div>
                <MainButton onClick={show}>{t("helpUsButton")}</MainButton>
              </div>
            </Fade>
          </DarkContainer>
        }
        left={false}
      />
      <ConfettiDisplayer
        show={confetti}
        stop={() => setConfetti(false)}
        colors={[[239, 175, 100], [211, 99, 78]]}
      />
      <Modal
        visible={visible}
        onClose={hide}
        animation={"zoom"}
        customStyles={{
          display: "flex",
          animationDuration: "300ms",
          maxWidth: "100%",
          height: "60vh",
        }}
      >
        <>
          <div className="zIndex1 modalBody">
            <h3>{t("donate_header")}</h3>
            <p>
              <Trans i18nKey="donate_text" />
            </p>
            <div>
              <Trans
                i18nKey="donate_details"
                components={{ i: <i />, b: <b />, h4: <h4 />, br: <br /> }}
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  )
}

const Modal = styled(Rodal)`
  .rodal-dialog {
    background: #222;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  text-align: center;
  .large-text {
    font-size: 20px;
    line-height: 30px;
    font-family: "Open Sans", sans-serif;
  }
  .zIndex1 {
    z-index: 1;
  }
  .rodal-close {
    z-index: 900;
  }
  .modalBody {
    max-height: 100%;
    max-width: 90vw;
    overflow: auto;
    scrollbar-width: thin;
    padding: 35px 17px;
    font-size: 22px;
    -webkit-overflow-scrolling: touch;
  }
`

const TextCenter = styled.div`
  text-align: center;
`
const CenterHeading = styled.h2`
  font-size: 42px;
  text-align: center;
`

export default IndexPage

export const FlexCenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  justify-content: center;

  iframe {
    max-width: 100%;
  }
`
