import React from "react"

export const Map = () => (
  <iframe
    title="map"
    id="map"
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7770.778700627507!2d80.1928472!3d13.1378231!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52649a87f9ac67%3A0x4f278094de285299!2sLittle+Lambs+school!5e0!3m2!1sen!2sse!4v1560874941961!5m2!1sen!2sse"
    width="100%"
    height="550"
    frameBorder="0"
    style={{ border: 0, background: "#ddd" }}
    allowFullScreen
  ></iframe>
)
