import * as React from "react"

// import PropTypes from 'prop-types'
const NUM_CONFETTI = 150
const PI_2 = 2 * Math.PI

const range = (a, b) => (b - a) * Math.random() + a

const drawCircle = function(context, x, y, r, style) {
  context.beginPath()
  context.arc(x, y, r, 0, PI_2, false)
  context.fillStyle = style
  return context.fill()
}

let xpos = 0.5

class Confetti {
  style: any
  rgb: string
  r: number
  r2: number
  context2d: any
  w: any
  h: any
  stopped: any
  opacity: number
  dop: number
  x: any
  y: any
  xmax: number
  ymax: number
  vx: number
  vy: any
  constructor(context, colors, w, h) {
    this.style = colors[~~range(0, colors.length)]
    this.rgb = `rgba(${this.style}`
    this.r = ~~range(2, 6)
    this.r2 = 2 * this.r
    this.replace()
    this.context2d = context
    this.w = w
    this.h = h
  }

  replace() {
    if (this.stopped) {
      return
    }
    this.opacity = 0
    this.dop = 0.03 * range(1, 4)
    this.x = range(-this.r2, this.w - this.r2)
    this.y = range(-20, this.h - this.r2)
    this.xmax = this.w - this.r
    this.ymax = this.h - this.r
    this.vx = range(0, 2) + 8 * xpos - 5
    return (this.vy = 0.7 * this.r + range(-1, 1))
  }

  stop() {
    this.stopped = true
  }
  draw() {
    this.x += this.vx
    this.y += this.vy
    this.opacity += this.dop
    if (this.opacity > 1) {
      this.opacity = 1
      this.dop *= -1
    }
    if (this.opacity < 0 || this.y > this.ymax) {
      this.replace()
    }
    if (!(0 < this.x && this.x < this.xmax)) {
      this.x = (this.x + this.xmax) % this.xmax
    }
    return drawCircle(
      this.context2d,
      ~~this.x,
      ~~this.y,
      this.r,
      `${this.rgb},${this.opacity})`
    )
  }
}

// const COLORS = [
//   [85, 71, 106],
//   [174, 61, 99],
//   [219, 56, 83],
//   [244, 92, 68],
//   [248, 182, 70],
// ]

const max_frames = 60 * 2
let counter = 0

export default class ConfettiDisplayer extends React.Component<{
  colors: number[][]
  show: boolean
  stop: () => void
}> {
  confetti: any[]
  _frameId: any
  frameId: number
  canvas: HTMLCanvasElement
  width: number
  height: number
  context2d: any
  componentDidMount() {
    this.width = window.innerWidth
    this.height = window.innerHeight
  }

  componentWillUnmount() {
    this.stopLoop()
  }

  startLoop = () => {
    this.confetti = []
    for (let i = 1; i <= NUM_CONFETTI; i++) {
      this.confetti.push(
        new Confetti(this.context2d, this.props.colors, this.width, this.height)
      )
    }
    if (!this._frameId) {
      this._frameId = window.requestAnimationFrame(this.loop)
    }
  }
  componentDidUpdate() {
    if (this.props.show) {
      this.startLoop()
    }
  }

  loop = () => {
    counter++
    //stop the loop
    if (counter > max_frames + 60) {
      this.stopLoop()
      return
    }
    if (this.context2d) {
      this.context2d.clearRect(0, 0, this.width, this.height)
      for (let c of this.confetti) {
        if (counter > max_frames) {
          c.stop()
        }
        c.draw()
      }
    }
    // Set up next iteration of the loop
    this.frameId = window.requestAnimationFrame(this.loop)
  }

  stopLoop = () => {
    counter = 0
    this.props.stop()
    window.cancelAnimationFrame(this._frameId)
    this._frameId = null
    // Note: no need to worry if the loop has already been cancelled
    // cancelAnimationFrame() won't throw an error
  }
  render() {
    if (this.props.show) {
      return (
        <canvas
          ref={ref => {
            this.canvas = ref
            if (ref) {
              this.context2d = ref.getContext("2d")
            }
          }}
          width={this.width}
          height={this.height}
          style={{
            position: "fixed",
            top: 60,
            bottom: 0,
            left: 0,
            right: 0,
            pointerEvents: "none",
            zIndex: 20,
          }}
        />
      )
    }
    return null
  }
}

// ConfettiDisplayer.propTypes = {
//   colors: PropTypes.arrayOf(PropTypes.string).isRequired,
//   width: PropTypes.number.isRequired,
//   height: PropTypes.number.isRequired,
// }
